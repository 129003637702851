<template>
  <div class="echart" v-loading="loading">
    <div class="notes">
      <div class="total">{{ total }}</div>
      <div class="title">Note Of Protests Issued</div>
      <div class="items">
        <div class="item">
          <div>{{ barge }}</div>
          <h5>Barge</h5>
        </div>
        <div class="item">
          <div>{{ vessel }}</div>
          <h5>Vessel</h5>
        </div>
        <div class="item">
          <div>{{ others }}</div>
          <h5>Others</h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getJobSummaryLetterOfProtest } from '@/services/modules'

export default {
  name: "NoteOfProtestsIssued",
  props: {
    startDate: {
      type: String
    },
    endDate: {
      type: String
    },
    mock: {
      type: Boolean,
      default: false,
    }
  },
  async created() {
    await this.getData()
  },
  data() {
    return {
      loading: true,
      total: 0,
      barge: 0,
      vessel: 0,
      others: 0,
    }
  },
  methods: {
    async getData() {
      this.loading = true
      const res = await getJobSummaryLetterOfProtest(this.startDate, this.endDate, this.mock)
      this.total = res?.total ?? 0
      this.barge = res?.barge ?? 0
      this.vessel = res?.vessel ?? 0
      this.others = res?.others ?? 0
      this.loading = false
    }
  }
}
</script>
<style scoped>
.echart {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
}
.notes {
  height: 300px; 
  max-width: 100%;
  background-color: white; 
  border: 1px solid #E5E5E5; 
  border-radius: 10px; 
  padding: 10px;
  font-family: 'Inter, "Helvetica Neue", Arial, sans-serif';

  .total {
    font-size: 1.15em;
    font-weight: bold;
    color: #464646;
  }

  .title {
    font-size: 0.8em;
    font-weight: 400;
    line-height: 30px;
    color: #6E7079;
  }
  
  .items {
    display: flex;
    width: 100%;
    padding-top: 50px;

    .item {
      flex: 0 0 33.33%;
      text-align: center;
      border-right: 2px solid #E5E5E5;
      height: 150px;
      padding: 20px 0px;

      div {
        height: 80px;
        font-size: 40px;
        font-weight: normal;
        padding-top: 40px;
      }
    }

    .item:last-child {
      border-right: none;
    }
  }
}
</style>